import useDocumentTitle from '../utils/useDocumentTitle';
import '../components/vintage/vintage.css';



const PreStre = () => {
    useDocumentTitle('Stress');

    return (
            <div className="vintage">
                <div style={{
                    maxWidth: '800px',
                    margin: '0 auto',
                    lineHeight: '1.8',
                    fontSize: '1.2rem',
                    color: '#2c2c2c'
                }}>
                    <p>
                        Stress is a feeling that we all experience at some point in our lives. 
                        It is a natural response to a situation that we perceive as threatening or challenging.
                    </p>
                </div>
            </div>
    );
};

export default PreStre;