/* eslint-disable import/no-webpack-loader-syntax */
import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { articles } from './articles/articlesConfig';

const WritingRouter = () => {
    const { writingName } = useParams();
    const [Component, setComponent] = React.useState(null);

    React.useEffect(() => {
        const article = articles.find(a => a.id === writingName);
        if (article) {
            article.component().then(module => {
                setComponent(() => module.default);
            });
        }
    }, [writingName]);

    if (!Component) {
        return <div>Loading...</div>;
    }

    return <Component />;
};

export default WritingRouter;
