export const articles = [
    {
        id: 'marble',
        title: 'Marble',
        date: '22 May, 2022',
        description: 'Why marble is always colder or hotter than any other material its around?',
        component: () => import('../writings/marble'),
    },
    {
        id: 'stanley',
        title: 'Stanley',
        date: '21 Nov, 2022',
        description: 'This is the story of a man named Stanley.',
        component: () => import('../writings/stanley'),
    }
]; 