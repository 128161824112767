import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes,} from 'react-router-dom';
import WritingRouter from './WritingRouter';
import {Four04} from './components/404/four04';
import {ThemeProvider} from './utils/ThemeContext';
import GlobalStyles from './utils/GlobalStyles';
import PreStre from './writings/pre-stre';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <React.StrictMode>
        <GlobalStyles/>
        <BrowserRouter>
            <ThemeProvider>
                <Routes>
                    <Route path="/" element={<App/>}/>
                    <Route path="qqq" element={<PreStre/>}/>

                    <Route path="writings" element={<WritingRouter/>}>
                        <Route path=":writingName"/>
                    </Route>
                    <Route path="*" element={<Four04/>}/>
                </Routes>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);